.faq-section {
    background-color: #FFFFFF;
    padding: 40px 20px;
    max-width: 974px;
    margin: 0 auto;
    border-radius: 20px 0px 0px 0px;
}


.questions-and-anwers-componet .faq-title {
    font-family: 'Aeonik', sans-serif;
    font-size: 36px;
    font-weight: 900;
    line-height: 43.2px;
    letter-spacing: 0.02em;
    text-align: center;
    color: #6C2BD9;
    margin-bottom: 20px;
}


.questions-and-anwers-componet .accordion {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.questions-and-anwers-componet .accordion-item {
    background: #F4F4F4FD;
    padding: 16px 32px;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    box-shadow: 0px 0px 50px 0px #00000008;
}

.questions-and-anwers-componet .accordion-item:hover {
    box-shadow: 0px 0px 50px 0px #00000015;
}

.questions-and-anwers-componet .accordion-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.questions-and-anwers-componet .question {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #000000;
    margin-right: 10px;
}

.questions-and-anwers-componet .answer {
    margin-top: 20px;
    font-family: 'SF Pro Display', sans-serif;
    font-size: 20px;
    font-weight: 100;
    line-height: 22px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #000000;
}

.questions-and-anwers-componet .icon {
    display: none;
    transition: opacity 0.3s ease;
}

.questions-and-anwers-componet .plus-icon {
    display: inline;
    width: 20px;
    height: 20px;
}

.questions-and-anwers-componet .accordion-item.active .plus-icon {
    display: none;
}

.questions-and-anwers-componet .accordion-item.active .minus-icon {
    display: inline;
    width: 20px;
    height: 5px;
}

.questions-and-anwers-componet .accordion-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease;
    padding-top: 10px;
}

.questions-and-anwers-componet .accordion-item.active .accordion-content {
    max-height: 100px;
}


@media (max-width: 768px) {
    .questions-and-anwers-componet .faq-title {
        font-size: 28px;
        line-height: 34px;
    }

    .questions-and-anwers-componet .accordion-item {
        padding: 16px 20px;
    }

    .questions-and-anwers-componet .plus-icon {
        display: inline;
        width: 16px;
        height: 16px;
    }

    .questions-and-anwers-componet .minus-icon {
        width: 16px;
        height: 5px;
    }
}