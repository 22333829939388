/* Background and Layout */
.main-header-component .ai-section {
    background: radial-gradient(68.69% 84.98% at 49.26% 103.84%, #430A5A 0%, #19002C 100%);
    position: relative;
    padding: 80px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    min-height: 400px; /* Adjust height as needed */
    overflow: hidden;
}

.main-header-component .background-images {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0; /* Behind the content */
}

.main-header-component .top-left-image {
    position: absolute;
    top: 0px; /* Adjust as needed */
    left: 0px; /* Adjust as needed */
    opacity: 0.8; /* Adjust opacity as needed */
    width: 20.5rem; /* Adjust size as needed */
}

.main-header-component .bottom-right-image {
    position: absolute;
    bottom: 0px; /* Adjust as needed */
    right: 0px; /* Adjust as needed */
    opacity: 0.8; /* Adjust opacity as needed */
    width: 20.5rem; /* Adjust size as needed */
}

/* Text Styling */
.main-header-component .content {
    position: relative;
    z-index: 1; /* In front of background images */
}

.main-header-component .main-title {
    font-family: 'Aeonik', sans-serif;
    font-size: 48px;
    font-weight: 900;
    line-height: 60px;
    color: #9251FF;
    margin: 0;
}

.main-header-component .description {
    font-family: 'Aeonik', sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    max-width: 800px; /* Adjust to control text width */
    margin: 20px auto 0; /* Center and add spacing */
}

/* Responsive Design */
@media (max-width: 768px) {
    .main-header-component .main-title {
        font-size: 36px;
        line-height: 48px;
    }

    .main-header-component .description {
        font-size: 18px;
        line-height: 28px;
    }
}