.footer-component .footer {
  background-color: #000000;
  color: #FFFFFF;
  padding: 40px 20px;
  text-align: center;
}

.footer-component .footer-top {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.footer-component .footer-logo-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-component .footer-logo {
  max-width: 100px;
  margin-bottom: 10px;
}

.footer-component .footer-company-name {
  font-family: 'Aeonik', sans-serif;
  font-size: 24px;
  font-weight: 700;
  margin: 0;
}

.footer-component .footer-links {
  display: flex;
  gap: 15px;
  font-family: 'Aeonik', sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.footer-component .footer-links a {
  color: #FFFFFF;
  text-decoration: none;
}

.footer-component .footer-links a:hover {
  color: #A0A0A0;
}

.footer-component .separator {
  color: #FFFFFF;
  font-size: 16px;
}


.footer-component .footer-social {
  display: flex;
  gap: 50px;
}

.footer-component .social-icon {
  width: 30px;
  transition: transform 0.3s ease;
}

.footer-component .social-icon:hover {
  transform: scale(1.1);
}


.footer-component .footer-bottom {
  border-top: 1px solid #333333;
  padding-top: 10px;
  margin-top: 20px;
}

.footer-component .footer-bottom p {
  font-family: 'SF Pro Display', sans-serif;
  font-size: 14px;
  color: #FFFFFF;
  margin: 0;
  margin-top: 20px;
}


@media (max-width: 768px) {
  .footer-component .footer-top {
    gap: 15px;
  }

  .footer-component .footer-links {
    flex-direction: column;
  }

  .footer-component .footer-social {
    gap: 30px;
  }
}