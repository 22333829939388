.services-component .ai-business-section {
    background-color: #f9f1ff;
    padding: 60px 20px;
    display: grid;
    justify-content: center;
    align-items: center;
}

.services-component .services-title-section {
    margin-inline: 20%;
    text-align: center;
}

.services-component .ai-container {
    display: flex;
    max-width: 1200px;
    align-items: flex-start;
    gap: 40px;
    flex-wrap: wrap;

    justify-content: space-between;
}

.services-component .ai-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 600px;
    color: #333333;
}

.services-component .ai-main-title {
    font-family: "Aeonik", sans-serif;
    font-size: 36px;
    font-weight: 900;
    color: #6c2bd9;
    margin-bottom: 20px;
}

.services-component .ai-subtitle {
    font-family: "SF Pro Display", sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    margin-bottom: 30px;
}

.services-component .ai-services {
    margin-bottom: 30px;
    margin-right: 100px;
}

.services-component .ai-service-item {
    margin-bottom: 20px;
    cursor: pointer;
    padding: 10px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.services-component .ai-service-item:hover {
    transform: scale(1.05);
}

.services-component .ai-service-title {
    font-family: "Aeonik", sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 28.8px;
    letter-spacing: 0.02em;
    text-align: left;

    color: rgba(18, 18, 18, 0.7);
    border-bottom: 1px solid rgba(18, 18, 18, 0.7);
    transition: border-bottom 0.4s ease, color 0.4s ease;
    padding-bottom: 10px;
}

.services-component .ai-service-item.active .ai-service-title,
.services-component .ai-service-item:hover .ai-service-title {
    font-family: "Aeonik", sans-serif;
    font-weight: 900;
    font-size: 28px;
    line-height: 33.6px;
    color: rgba(108, 43, 217, 1);
    border-bottom: 3px solid rgba(108, 43, 217, 1);
}

.services-component .ai-service-description {
    font-family: "SF Pro Display", sans-serif;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
    border-bottom: 2px solid #121212b2;
    padding-bottom: 10px;
    display: none;
    transition: opacity 0.3s ease, transform 0.3s ease;
    padding-top: 12px;
    line-height: 22px;
    color: rgba(65, 65, 65, 1);
}

.services-component .ai-service-item.active .ai-service-description {
    display: block;
    opacity: 1;
    transform: translateY(0);
}

.services-component .ai-service-item .ai-service-description {
    opacity: 0;
    transform: translateY(10px);
}

.services-component .ai-consultation {
    font-family: "SF Pro Display", sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 30px;
    color: rgba(18, 18, 18, 1);
}

.services-component .ai-contact-button {
    display: inline-block;
    padding: 15px 30px;
    background-color: #6c2bd9;
    color: #ffffff;
    text-decoration: none;
    border-radius: 363px;
    font-family: "SF Pro Display", sans-serif;
    font-size: 16px;
    font-weight: 500;
    transition: background-color 0.3s ease, opacity 0.3s ease;
    width: 192px;
    text-align: center;
}

.services-component .ai-contact-button:hover {
    opacity: 0.8;
}

.services-component .ai-service-image {
    width: 450px;
    height: auto;
    border-radius: 10px;
    transition: opacity 0.5s ease, transform 0.5s ease;
}

.ai-service-item.active+.ai-service-image {
    transform: scale(1.05);
}

@media (max-width: 1024px) {
    .services-component .ai-container {
        flex-direction: column;
        align-items: center;
        justify-self: center;
    }

    .services-component .ai-content {
        text-align: left;
    }

    .services-component .ai-image-container {
        display: none;
    }

    .services-component .services-title-section {
        margin-inline: 0%;
    }

    .services-component .ai-services {
        margin-right: 0px;
    }
}