.horizontal-cards-component .partners-section {
    background-color: #FFFFFF;
    padding: 40px 20px;
    text-align: center;
}

.horizontal-cards-component .section-title {
    font-family: 'Aeonik', sans-serif;
    font-size: 36px;
    font-weight: 900;
    color: #6C2BD9;
    margin-bottom: 20px;
}


.horizontal-cards-component .partners-container {
    display: flex;
    overflow-x: auto;
    gap: 20px;
    padding-bottom: 20px;
    scrollbar-width: none;
}

.horizontal-cards-component .partners-container::-webkit-scrollbar {
    display: none;
}


.horizontal-cards-component .partner-card {
    background-color: #FFFFFF;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    min-width: 440px;
    max-width: 440px;
    height: 440px;
    transition: transform 0.5s ease-in-out, box-shadow 0.5s ease-in-out;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.horizontal-cards-component .partner-card:hover {
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);
}


.horizontal-cards-component .partner-card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0);
    transition: background 0.5s ease-in-out;
    z-index: 1;
}

.horizontal-cards-component .partner-card:hover::before {
    background: rgba(0, 0, 0, 0.4);
}

.horizontal-cards-component .card-content {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.horizontal-cards-component .card-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    transition: opacity 0.5s ease-in-out;
}


.horizontal-cards-component .card-text {
    position: relative;
    padding: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 2;
    transition: transform 0.5s ease-in-out;
    text-align: center;
}

.horizontal-cards-component .partner-title {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 36px;
    font-weight: 700;
    color: #FFFFFF;
    margin: 0;
    text-align: center;
}


.horizontal-cards-component .card-hover-content {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: opacity 0.5s ease, max-height 0.5s ease;
}

.horizontal-cards-component .partner-card:hover .card-hover-content {
    opacity: 1;
    max-height: 300px;
}


.horizontal-cards-component .partner-card:hover .card-text {
    justify-content: flex-start;
    padding-top: 20px;
    transform: translateY(-30px);
}

.horizontal-cards-component .partner-description {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #FFFFFF;
    margin-top: 10px;
}

.horizontal-cards-component .visit-button {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #FFFFFF;
    color: #6C2BD9;
    text-decoration: none;
    border: 1px solid #6C2BD9;
    border-radius: 363px;
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    font-weight: 500;
    transition: background-color 0.5s ease, color 0.5s ease;
}

.horizontal-cards-component .visit-button:hover {
    background-color: #6C2BD9;
    color: #FFFFFF;
}


@media (max-width: 768px) {
    .horizontal-cards-component .partner-card {
        min-width: 300px;
        max-width: 300px;
        height: 300px;
    }

    .horizontal-cards-component .partner-title {
        font-size: 30px;
    }

    .horizontal-cards-component .partner-description {
        font-size: 14px;
    }
}