
.image-title-description-componet .responsive-section {
    background-color: #F8F8FB;
    padding: 40px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 400px;
}

.image-title-description-componet .content-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    max-width: 1200px;
}

.image-title-description-componet .responsive-image {
    width: 50%;
    max-width: 600px;
    height: auto;
    border-radius: 8px;
}

.image-title-description-componet .text-container {
    flex: 1;
}

.image-title-description-componet .title {
    font-family: 'Aeonik', sans-serif;
    font-size: 32px;
    font-weight: 700;
    color: #000000;
    margin-bottom: 20px;
}

.image-title-description-componet .description {
    font-family: 'Aeonik', sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.5;
    color: #555555;
}

@media (max-width: 768px) {
    .image-title-description-componet .content-container {
        flex-direction: column-reverse;
        text-align: center;
        gap: 20px;
    }

    .image-title-description-componet .responsive-image {
        width: 100%;
        max-width: none;
    }

    .image-title-description-componet .title {
        font-size: 28px;
    }

    .image-title-description-componet .description {
        font-size: 16px;
    }
}