.our-partners-component .partners-section {
    background-color: #000000;
    padding: 40px 0;
    text-align: center;
}

.our-partners-component .partners-title {
    font-family: 'Aeonik', sans-serif;
    font-size: 24px;
    font-weight: 700;
    color: #FFFFFF;
    margin-bottom: 30px;
}

.our-partners-component .partners-logos {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
}

.our-partners-component .partner-logo img {
    max-width: 250px;
    height: auto;
    transition: transform 0.3s ease;
}

.our-partners-component .partner-logo img:hover {
    transform: scale(1.1);
}

@media (max-width: 768px) {
    .our-partners-component .partners-logos {
        flex-direction: column;
        gap: 20px;
    }

}